import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaSortUp, FaSortDown } from "react-icons/fa";

const AdminPlayers = () => {
  const [players, setPlayers] = useState([]);
  const [sortedPlayers, setSortedPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortKey, setSortKey] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" ou "desc"

  const fetchPlayers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/allPlayers`
      );
      setPlayers(response.data.devices);
    } catch (error) {
      console.error("Error fetching players:", error);
      setError("Erreur lors de la récupération des players.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  // Met à jour le tri chaque fois que players, sortKey ou sortOrder changent
  useEffect(() => {
    const sorted = [...players].sort((a, b) => {
      let aValue, bValue;

      if (sortKey === "status") {
        aValue = isOnline(a.lastOnline) ? 1 : 0;
        bValue = isOnline(b.lastOnline) ? 1 : 0;
      } else {
        aValue = a[sortKey]?.toLowerCase() || "";
        bValue = b[sortKey]?.toLowerCase() || "";
      }

      if (aValue < bValue) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
    setSortedPlayers(sorted);
  }, [players, sortKey, sortOrder]);

  // Fonction pour vérifier si un player est en ligne
  const isOnline = (lastOnline) => {
    const now = new Date();
    const lastOnlineDate = new Date(lastOnline);
    const diffMinutes = (now - lastOnlineDate) / (1000 * 60);
    return diffMinutes <= 5; // Considéré en ligne si actif dans les 5 dernières minutes
  };

  const handleSort = (key) => {
    if (sortKey === key) {
      // Inverse l'ordre si on clique sur la même colonne
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortKey(key);
      setSortOrder("asc");
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Liste des Players</h1>
      {loading ? (
        <p>Chargement en cours...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : players.length === 0 ? (
        <p>Aucun player trouvé.</p>
      ) : (
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th
                className="border border-gray-300 px-4 py-2 cursor-pointer"
                onClick={() => handleSort("name")}
              >
                <div className="flex items-center justify-center gap-1">
                  Nom
                  {sortKey === "name" && (
                    <span
                      className={`text-xs ${sortOrder === "asc" ? "mt-2" : ""}`}
                    >
                      {sortOrder === "asc" ? <FaSortUp /> : <FaSortDown />}
                    </span>
                  )}
                </div>
              </th>
              <th
                className="border border-gray-300 px-4 py-2 cursor-pointer"
                onClick={() => handleSort("email")}
              >
                <div className="flex items-center justify-center gap-1">
                  Email
                  {sortKey === "email" && (
                    <span
                      className={`text-xs ${sortOrder === "asc" ? "mt-2" : ""}`}
                    >
                      {sortOrder === "asc" ? <FaSortUp /> : <FaSortDown />}
                    </span>
                  )}
                </div>
              </th>
              <th
                className="border border-gray-300 px-4 py-2 text-center cursor-pointer"
                onClick={() => handleSort("status")}
              >
                <div className="flex items-center justify-center gap-1">
                  Statut
                  {sortKey === "status" && (
                    <span
                      className={`text-xs ${sortOrder === "asc" ? "mt-2" : ""}`}
                    >
                      {sortOrder === "asc" ? <FaSortUp /> : <FaSortDown />}
                    </span>
                  )}
                </div>
              </th>
              <th className="border border-gray-300 px-4 py-2 text-center">
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {sortedPlayers.map((player) => (
              <tr key={player._id} className="hover:bg-gray-100">
                <td className="border border-gray-300 px-4 py-2">
                  {player.name}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {player.email}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  <div
                    className={`w-4 h-4 rounded-full mx-auto ${
                      isOnline(player.lastOnline)
                        ? "bg-green-500"
                        : "bg-red-500"
                    }`}
                  ></div>
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => console.log(`Edit player: ${player._id}`)}
                  >
                    <FaEdit />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminPlayers;
