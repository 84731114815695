import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

const SubscriptionTracker = ({ children }) => {
  useEffect(() => {
    const hasVisitedSubscription = localStorage.getItem(
      "hasVisitedSubscription"
    );

    if (!hasVisitedSubscription) {
      // L'utilisateur visite cette page pour la première fois
      ReactPixel.track("CompleteRegistration", {
        registrationMethod: "Clerk",
      });
      console.log(
        "Événement CompleteRegistration envoyé pour la première visite de /subscription"
      );

      // Marquer comme visité pour éviter d'envoyer à nouveau l'événement
      localStorage.setItem("hasVisitedSubscription", "true");
    }
  }, []);

  return <>{children}</>;
};

export default SubscriptionTracker;
