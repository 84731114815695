import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactPixel from "react-facebook-pixel";
import SubscriptionTracker from "./pages/Subscription/SubscriptionTracker";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  SignIn,
  SignUp,
} from "@clerk/clerk-react";
import { frFR } from "@clerk/localizations";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import BuySubscription from "./pages/Subscription/BuySubscription";
import PaymentSuccess from "./pages/Stripe/PaymentSuccess";
import PaymentCancel from "./pages/Stripe/PaymentCancel";
import WebPlayer from "./pages/WebPlayer";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const root = ReactDOM.createRoot(document.getElementById("root"));

const ClerkWithRoutes = () => {
  const navigate = useNavigate();

  // Initialisation du Pixel Facebook
  useEffect(() => {
    const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
    const debugMode = process.env.REACT_APP_DEBUG === "true";

    if (!pixelId) {
      console.error("Facebook Pixel ID manquant !");
      return;
    }

    // Initialiser le Pixel
    ReactPixel.init(pixelId, {
      autoConfig: true,
      debug: debugMode,
    });

    // Capturer l'événement "PageView"
    ReactPixel.pageView();
    console.log("Pixel Facebook initialisé et événement PageView envoyé");
  }, []);

  return (
    <ClerkProvider
      localization={frFR}
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}
    >
      <Routes>
        {/* Clerk : Se connecter */}
        <Route
          path="/sign-in/*"
          element={
            <div className="flex items-center justify-center min-h-screen">
              <SignIn redirectUrl={"/"} routing="path" path="/sign-in" />
            </div>
          }
        />

        {/* Clerk : S'enregistrer */}
        <Route
          path="/sign-up/*"
          element={
            <div className="flex items-center justify-center min-h-screen">
              <SignUp redirectUrl={"/"} routing="path" path="/sign-up" />
            </div>
          }
        />

        {/* Landing Page */}
        <Route
          path="/"
          element={
            <>
              <SignedIn>
                <App />
              </SignedIn>
              <SignedOut>
                <div className="flex items-center justify-center min-h-screen">
                  <SignIn redirectUrl={"/"} path="/sign-in" />
                </div>
              </SignedOut>
            </>
          }
        />

        {/* Abonnement */}
        <Route
          path="/subscription"
          element={
            <>
              {/* Si connecté, afficher le contenu */}
              <SignedIn>
                <SubscriptionTracker>
                  <BuySubscription />
                </SubscriptionTracker>
              </SignedIn>

              {/* Si déconnecté, rediriger vers la page de connexion */}
              <SignedOut>
                <div className="flex items-center justify-center min-h-screen">
                  <SignIn redirectUrl={"/subscription"} path="/sign-in" />
                </div>
              </SignedOut>
            </>
          }
        />

        {/* Stripe : Payment Success */}
        <Route
          path="/success"
          element={
            <>
              {/* Si connecté, afficher la page de succès */}
              <SignedIn>
                <PaymentSuccess />
              </SignedIn>

              {/* Si déconnecté, rediriger vers la connexion */}
              <SignedOut>
                <div className="flex items-center justify-center min-h-screen">
                  <SignIn redirectUrl={"/success"} path="/sign-in" />
                </div>
              </SignedOut>
            </>
          }
        />

        {/* Stripe : Payment Cancel */}
        <Route
          path="/cancel"
          element={
            <>
              {/* Si connecté, afficher la page d'annulation */}
              <SignedIn>
                <PaymentCancel />
              </SignedIn>

              {/* Si déconnecté, rediriger vers la connexion */}
              <SignedOut>
                <div className="flex items-center justify-center min-h-screen">
                  <SignIn redirectUrl={"/cancel"} path="/sign-in" />
                </div>
              </SignedOut>
            </>
          }
        />

        {/* Web Player */}
        <Route
          path="/web-player/:deviceId"
          element={
            <>
              <SignedIn>
                <WebPlayer />
              </SignedIn>
              <SignedOut>
                <div className="flex items-center justify-center min-h-screen">
                  <SignIn
                    redirectUrl={"/web-player/:deviceId"}
                    path="/sign-in"
                  />
                </div>
              </SignedOut>
            </>
          }
        />
      </Routes>
    </ClerkProvider>
  );
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ClerkWithRoutes />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
