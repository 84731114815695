import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

// Enregistrer les éléments nécessaires de Chart.js
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

const AdminFinances = () => {
  const [mrr, setMrr] = useState(0);
  const [totalLicenses, setTotalLicenses] = useState(0);
  const [subscriptionData, setSubscriptionData] = useState({
    monthly: 0,
    annually: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchFinanceData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/mrr-details`
      );
      const { mrr, totalLicenses, subscriptionData } = response.data;

      setMrr(parseFloat(mrr));
      setTotalLicenses(totalLicenses);
      setSubscriptionData(subscriptionData);
    } catch (error) {
      console.error("Error fetching finance data:", error);
      setError("Erreur lors de la récupération des données financières.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFinanceData();
  }, []);

  const pieData = useMemo(
    () => ({
      labels: ["Mensuel", "Annuel"],
      datasets: [
        {
          data: [subscriptionData.monthly, subscriptionData.annually],
          backgroundColor: ["#7c85fc", "#db75a5"],
        },
      ],
    }),
    [subscriptionData]
  );

  const barData = useMemo(
    () => ({
      labels: ["Mensuel", "Annuel"],
      datasets: [
        {
          label: "Licences",
          data: [subscriptionData.monthly, subscriptionData.annually],
          backgroundColor: ["#7c85fc", "#db75a5"],
        },
      ],
    }),
    [subscriptionData]
  );

  const pieOptions = {
    plugins: {
      legend: {
        position: "top",
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  const barOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function (value) {
            if (Number.isInteger(value)) {
              return value;
            }
          },
        },
      },
    },
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">
        Revenu Récurrent Mensuel (MRR)
      </h1>
      {loading ? (
        <p>Chargement en cours...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="space-y-6">
          {/* Section Revenu */}
          <div className="bg-white shadow-md rounded p-6">
            <p className="text-xl font-semibold">
              Revenu récurrent mensuel :{" "}
              <span className="bg-gradient-to-r from-[#7c85fc] to-[#db75a5] bg-clip-text text-transparent">
                {mrr.toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>
            </p>
            <p className="mt-2 text-gray-600">
              Nombre total de licences :{" "}
              <span className="font-semibold">{totalLicenses}</span>
            </p>
          </div>

          {/* Graphiques */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Graphique en camembert */}
            <div className="bg-white shadow-md rounded p-6 h-[600px] flex flex-col">
              <h2 className="text-lg font-bold mb-4 text-center">
                Répartition des abonnements
              </h2>
              <div className="flex-1 flex items-center justify-center">
                <div className="w-full h-full">
                  <Pie data={pieData} options={pieOptions} />
                </div>
              </div>
            </div>

            {/* Graphique en barres */}
            <div className="bg-white shadow-md rounded p-6 h-[600px] flex flex-col">
              <h2 className="text-lg font-bold mb-4 text-center">
                Nombre de licences par type
              </h2>
              <div className="flex-1 flex items-center justify-center">
                <div className="w-full h-full">
                  <Bar data={barData} options={barOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminFinances;
